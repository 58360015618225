@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* fallback */
@font-face {
    font-family: 'Material Symbols Rounded';
    font-style: normal;
    font-weight: 100 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/materialsymbolsrounded/v177/sykg-zNym6YjUruM-QrEh7-nyTnjDwKNJ_190Fjzag.woff2) format('woff2');
}

.material-symbols-rounded {
    font-family: 'Material Symbols Rounded', emoji;
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    visibility: visible; 
}

.material-symbols-rounded:before {
    visibility: hidden;
  }

body, #root {
    width: 100vw;
    height: 100vh;
    display: flex;
    font-size: 14px;
    overflow: hidden;
    /* overflow-y: auto; */
    /* font-family: "Public Sans", sans-serif; */
    font-family: "Open Sans"
}

.mso-fill {
    font-variation-settings: 'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
}

/* for the tool tip on admin */
.show-tip {
    @apply flex items-center;
}

.show-tip > div {
    visibility: hidden;
    max-width: 80vw;
}

.show-tip:hover > div {
    visibility: visible;
}

.card {
    @apply items-start rounded-lg border border-gray-50 p-5 bg-white shadow-sm;
}

.tool-tip-cover {
    @apply absolute bg-[#f8f8ff] drop-shadow-xl rounded-md h-fit;
}

.light-scroll::-webkit-scrollbar {
    width: 4px;
}

.light-scroll::-webkit-scrollbar-thumb {
    background-color: #1f1f1f;
    border-radius: 32px;
}

@keyframes notify {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }

    10% {
        transform: translateY(0);
        opacity: 100;
    }

    80% {
        opacity: 60;
    }

    100% {
        opacity: 0;
    }
}

#notiStack {
    z-index: 1000;
}

.notification {
    @apply flex items-center justify-between p-4 rounded-md mr-8 mb-8 gap-4 shadow opacity-0 z-50;
    animation: notify 5s;
}

.notification svg {
    width: 20px;
}

.notification-info {
    @apply bg-sky-100 text-sky-600 fill-sky-600;
}

.notification-success {
    @apply bg-green-100 text-green-800 fill-green-800;
}

.notification-alert {
    @apply bg-red-100 text-red-800 fill-red-800;
}

.news-scroll::-webkit-scrollbar {
    width: 4px;
    @apply bg-gray-200;
}

/* Handle */
.news-scroll::-webkit-scrollbar-thumb {
    @apply rounded-md bg-gray-800;
}

.gray-scroll::-webkit-scrollbar-thumb {
    @apply bg-gray-300
}


@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    @apply duration-100 ease-in;
    transition-property: width, height;
}

.custom-slider {
    @apply appearance-none h-0.5 bg-green-200 max-w-48 flex-1
}

.custom-slider::-webkit-slider-thumb {
    @apply appearance-none w-2 aspect-square rounded-full bg-green-600 cursor-pointer
}


.expand-contract-lg {
    display: flex!important;
    @apply lg:hidden;
}


.expand-contract-xl {
    display: flex!important;
    @apply xl:hidden;
}