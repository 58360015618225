/* body{
    font-family: 'Public Sans';
} */
body {
    font-family: 'Poppins';
}

html {
    scroll-behavior: smooth;
    scroll-padding-top: 100px; /* Increased padding to ensure enough space */
}

:root {
    scroll-behavior: smooth;
}