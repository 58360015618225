@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Poppins";
}

.scroll-container{
  overflow-y: auto;
  max-height: 100%;
  padding: 10px;
}