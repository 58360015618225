.custom-button {
  display: inline-block;
  width: 150px;
  height: 45px;
  color: #212b36;
  font-size: 1rem;
  font-family: Public Sans;
  /* letter-spacing: 0.15rem; */
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: all 0.3s;
}

.custom-button:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #B8F668; /* Darkened version of the hover color */
  transition: all 0.3s;
  z-index: -1;
}

.custom-button:hover {
  color: #fff;
}

.custom-button:hover:before {
  width: 100%;
}
